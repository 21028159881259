import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Listing from '../components/projects/listing'
import Pagination from '../components/layout/pagination'
import { Layout } from '../components/global/layout'
import Masthead from '../components/layout/masthead'

const ProjectIndex = ({ data, pageContext }) => {
  if (!data) 
    return null

  const document = data.prismicOiProjectIndex
  const articles = document.data.documents.slice(pageContext.pageInfo.start, pageContext.pageInfo.end)

  const projects = []

  articles.map((article, i) => {
    return (
      projects.push(article.project.document)
    )
  })
  
  return (
    <Layout pageTitle={document.data.title.text} 
            metaTitle={document.data.meta_title.text} 
            metaDescription={document.data.meta_description.text || document.data.summary.text}
            shareImage={document.data.share_image.url}>
      <Masthead subtitle={document.data.subtitle.text}
                title={document.data.title.text}
                summary={document.data.summary.text} />
      <Listing data={projects} />
      <Pagination pageInfo={pageContext.pageInfo} basePath={document.url} />
    </Layout>
  )
}

export const query = graphql`
  query ProjectIndexPageQuery {
    prismicOiProjectIndex {
      _previewable
      data {
        title {
          text
        }
        summary {
          text
        }
        subtitle {
          text
        }
        share_image {
          url
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        documents {
          project {
            document {
              ... on PrismicOiProject {
                id
                data {
                  image {
                    url
                    alt
                  }
                  summary {
                    text
                  }
                  title {
                    text
                  }
                  service
                  industry
                }
                tags
              }
            }
          }
        }
      }
      tags
      url
    }
  }
`

export default withPrismicPreview(ProjectIndex)